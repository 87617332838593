@import 'mantine';
.driveSectionBlock {
  padding-bottom: rem(40);
  overflow: hidden;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    padding-bottom: rem(120);
    padding-top: rem(120);
  }
}

.serviceName {
  color: var(--mantine-color-gray-7);
}

.driveSectionImage {
  object-fit: contain;
  object-position: center;
  max-width: 100%;
}

.driveSectionContent {
  padding-top: rem(32);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    padding-top: initial;
  }
}
